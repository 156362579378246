import AppGlobalsContext from "app/AppGlobalsContext";
import Button from "app/pages/.shared/form/Button";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import IconClose from "app/pages/.shared/IconClose";
import IconDeparting from "app/pages/.shared/IconDeparting";
import IconLocation from "app/pages/.shared/IconLocation";
import IconMagnifyingGlass from "app/pages/.shared/IconMagnifyingGlass";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ClearIndicatorSdpSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/ClearIndicatorSdpSearchInput";
import DestinationInputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/DestinationInputValueFormat";
import LocationMenuList from "app/pages/SmartDP/Search/SDPSearchForm/LocationMenuList";
import SmartDPDateCalendarContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPDateCalendarContainer";
import {
	defaultValues,
	validateDepartureCity,
	validateDepartureDate,
	validateDestination,
	validateSearch,
} from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import SmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchInput";
import TravellersRoomButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomButton";
import { destinationResortFilterOptions, isServerSide, sortOptions } from "app/utils/utils";
import classNames from "classnames";
import { differenceInDays, format } from "date-fns";
import { Form, Formik } from "formik";
import { isEmpty, orderBy } from "lodash";
import get from "lodash/get";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useMatch } from "react-router-dom";
import "./SmartDPSearchForm.scss";
import {
	BRANDS,
	DESTINATION_USA_CODE,
	FS_QUOTE_EVENT_NAME,
	SDP_ROTATION_DATE_FORMAT,
	WEBSITE_TYPE,
} from "app/constants";
import CalendarDisabledView from "app/pages/SmartDP/Search/SDPSearchForm/CalendarDisabledView";
import TravellersRoomDrawerButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomDrawerButton";
import { EventCategory, HitType, useFlagship, useFsFlag } from "@flagship.io/react-sdk";
import { brandPropTypes, websiteTypeShape } from "app/utils/propTypes";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import InputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/InputValueFormat";

const SmartDPSearchForm = ({
	onSuccess = () => {},
	departureCities = [],
	isStopover,
	stopoverCities = [],
	stopoverDirections = [],
	destinations = [],
	cabins = [],
	initialValues = {},
	hideSidePanel = () => {},
	topDestinationsList = [],
	showMiniSDPForm = false,
	validateOnMount,
	componentToOverlayRef = {},
	brand,
	displayLoader,
	errorScrollIntoView = true,
	websiteType,
	resetAllSDPProductsFilter = () => {},
}) => {
	const { hit: fsHit } = useFlagship();

	const isListingSdpDisplayedFlag = useFsFlag("listing_sdp_search_form_display", false);
	const isListingSdpDisplayed = isListingSdpDisplayedFlag.getValue();

	const [isTopDepartureCitiesMenuDisplayed, setIsTopDepartureCitiesMenuDisplayed] = useState(
		true
	);
	const [isTopDestinationsMenuDisplayed, setIsTopDestinationsMenuDisplayed] = useState(true);

	const { pathname } = useLocation();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const handleSubmit = useCallback(
		(values, actions) => {
			resetAllSDPProductsFilter();
			actions.setSubmitting(false);
			onSuccess(values, pathname);
		},
		[departureCities, destinations, cabins, pathname]
	);
	const [destinationInput, setDestinationInput] = useState();
	const [departureInput, setDepartureInput] = useState();
	// fix pour ne pas avoir les labels sur 2 lignes au premier rendu
	const mergeInitialValues = isServerSide
		? {
				occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
		  }
		: {
				...defaultValues,
				...initialValues,
		  };

	const isListingOrQuote =
		pathname.includes("/sdp/listing") ||
		pathname === "/sdp/booking/quote" ||
		pathname.includes("/stopover/listing") ||
		pathname === "/stopover/booking/quote";
	const isMerchPage = pathname.includes("/merch");

	const isSDPSearchPage = useMatch({
		path: "/sdp/search",
		end: true,
		caseSensitive: true,
	});
	const isSDPRootPage = useMatch({
		path: "/",
		end: true,
		caseSensitive: true,
	});

	const isSDPHomePage = isSDPRootPage || isSDPSearchPage;
	const isSticky =
		!isMobile && ["/sdp/listing", "/sdp/booking/quote"].some(path => pathname.includes(path));

	const hasCabins = isServerSide || cabins.length > 0;

	const topDepartureCities = orderBy(
		departureCities.filter(option => option?.isMain),
		["mainOrder"],
		["asc"]
	);
	const onHandleClickSearchButton = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.CLICK_ON_SEARCH_CTA,
		});
	}, []);

	const isPPListingPage = pathname.includes("/listing");
	const isBrandAF = brand === BRANDS.AF;

	let travelRoomsPopoverWidth = 0;
	let travelRoomsPopoverOffset = [0, 0];
	let departurePopoverWidth = 0;
	let departurePopoverOffset = [-1, 0];
	let calendarPopoverOffset = [0, 0];

	if (hasCabins) {
		if (isListingOrQuote) {
			if (isStopover) {
				travelRoomsPopoverWidth = 320;
				travelRoomsPopoverOffset = [-6, 11];
				departurePopoverWidth = 360;
				calendarPopoverOffset = [0, 0];
			} else {
				travelRoomsPopoverWidth = 298;
				travelRoomsPopoverOffset = [12, 11];
				departurePopoverWidth = 390;
				calendarPopoverOffset = [0, -9];
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (isStopover) {
				travelRoomsPopoverWidth = 340;
				travelRoomsPopoverOffset = [45, 11];
				departurePopoverWidth = 395;
				departurePopoverOffset = [-1, 0];
				calendarPopoverOffset = [0, -70];
			} else {
				travelRoomsPopoverWidth = 334;
				travelRoomsPopoverOffset = [83, 11];
				departurePopoverWidth = 355;
				departurePopoverOffset = [-17, -4];
				calendarPopoverOffset = [0, -9];
			}
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (isListingOrQuote) {
			if (isStopover) {
				travelRoomsPopoverWidth = 320;
				travelRoomsPopoverOffset = [5, 11];
				departurePopoverWidth = 410;
				calendarPopoverOffset = [0, 0];
			} else {
				travelRoomsPopoverWidth = 340;
				travelRoomsPopoverOffset = [8, 11];
				departurePopoverWidth = 395;
				calendarPopoverOffset = [0, -9];
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (isStopover) {
				travelRoomsPopoverWidth = 387;
				travelRoomsPopoverOffset = [70, 11];
				departurePopoverWidth = 395;
				departurePopoverOffset = [-1, 0];
				calendarPopoverOffset = [0, -70];
			} else {
				travelRoomsPopoverWidth = 387;
				travelRoomsPopoverOffset = [93, 6];
				departurePopoverWidth = 410;
				departurePopoverOffset = [-17, -6];
				calendarPopoverOffset = [0, -9];
			}
		}
	}

	const [refs, setRefs] = useState(null);

	const updateFloatingPositionReference = useCallback(refs => {
		setRefs(refs);
	}, []);

	return (
		<div className="sdp-search-form" ref={refs?.setPositionReference}>
			{(isListingOrQuote ||
				((isSDPHomePage || isMerchPage) && showMiniSDPForm) ||
				(isPPListingPage && isBrandAF && showMiniSDPForm)) &&
				isMobile && (
					<header className="sdp-search-form__header">
						<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold component="h3">
							<FormattedMessage id="sdp.search.home.input.label" />
						</Typography>
						<div className="advanced-select__header-icon">
							<IconClose width={24} height={24} onClick={hideSidePanel} />
						</div>
					</header>
				)}
			<Formik
				enableReinitialize
				initialValues={mergeInitialValues}
				validate={validateSearch}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
				initialTouched={
					validateOnMount && {
						departureCity: true,
						destinationResort: true,
						travelDates: {
							departureDate: true,
							endDate: true,
						},
						duration: {
							code: true,
						},
						occupancies: true,
						cabin: true,
					}
				}
				validateOnMount={validateOnMount}
			>
				{({ values, errors, isValid, isSubmitting, setFieldValue, submitCount }) => {
					const handleTravellersConfirmation = values => {
						setFieldValue("occupancies", values);
					};

					const selectedDestinationData = destinations.find(destination => {
						// can be string if from query or number if from filters.json
						return String(destination.code) === String(values?.destinationResort?.code);
					});

					const sdpRotationUri = selectedDestinationData?.departureCities?.find(
						city => city?.code === values?.departureCity?.code
					)?.uri;

					const isUsaDestination = DESTINATION_USA_CODE.includes(
						values?.destinationResort?.code
					);

					return (
						<>
							<Form className="sdp-search-form__form" data-testid="sdp-search-form">
								<SmartDPSearchInput
									{...(isStopover
										? {
												menuIsOpen: Boolean(departureInput),
										  }
										: {})}
									id="departureCity"
									name="departureCity"
									openMenuOnFocus={!values.departureCity}
									openMenuOnClick={!values.departureCity}
									className="sdp-search-form__field sdp-search-form__field-departure"
									data-testid="departure-city-input"
									validate={validateDepartureCity}
									label={
										<FormattedMessage id="sdp.search.departure.city.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.departure.city.input.label.mobile" />
									}
									components={{
										SingleValue: InputValueFormat,
										MenuList: LocationMenuList,
										ClearIndicator: ClearIndicatorSdpSearchInput,
									}}
									formatOptionLabel={({ label = "" }) => {
										return (
											<div className="sdp-search-form__suggestion">
												{!isMobile && (
													<div className="sdp-search-form__suggestion-picto">
														<IconDeparting />
													</div>
												)}
												<span
													className="sdp-search-form__suggestion-label"
													dangerouslySetInnerHTML={{
														__html: label,
													}}
												/>
											</div>
										);
									}}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDepartureCitiesMenuDisplayed
											? topDepartureCities
											: departureCities
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										departureCities.find(({ code }) => {
											return code === get(values, "departureCity.code");
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={departurePopoverWidth}
									popperOffset={departurePopoverOffset}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.departure.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDepartureCitiesMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDepartureCitiesMenuDisplayed) {
												setIsTopDepartureCitiesMenuDisplayed(true);
											}
										} else if (isTopDepartureCitiesMenuDisplayed) {
											setIsTopDepartureCitiesMenuDisplayed(false);
										}
										setDepartureInput(inputValue);
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
									loading={displayLoader}
								/>

								<SmartDPSearchInput
									{...(isStopover
										? { menuIsOpen: Boolean(destinationInput) }
										: {})}
									id="destinationResort"
									name="destinationResort"
									openMenuOnFocus={!values.destinationResort}
									openMenuOnClick={!values.destinationResort}
									validate={validateDestination}
									className="sdp-search-form__field sdp-search-form__field-destination"
									data-testid="destination-resort-input"
									filterOption={
										isTopDestinationsMenuDisplayed
											? undefined
											: destinationResortFilterOptions
									}
									label={
										<FormattedMessage id="sdp.search.destination.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.destination.input.label.mobile" />
									}
									formatOptionLabel={({ labels = [] }) => {
										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													<IconLocation />
												</div>
												<div className="sdp-search-form__suggestion-text">
													<span
														className="sdp-search-form__suggestion-first-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(-1).join(", "),
														}}
													/>
													<span
														className="sdp-search-form__suggestion-second-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(0, -1).join(", "),
														}}
													/>
												</div>
											</div>
										);
									}}
									components={{
										SingleValue: DestinationInputValueFormat,
										ClearIndicator: ClearIndicatorSdpSearchInput,
										MenuList: LocationMenuList,
									}}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDestinationsMenuDisplayed
											? topDestinationsList
											: sortOptions(destinations, destinationInput)
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										destinations.find(({ code }) => {
											return (
												code ===
												Number(get(values, "destinationResort.code"))
											);
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={hasCabins ? 490 : 412}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.destinations.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDestinationsMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDestinationsMenuDisplayed) {
												setIsTopDestinationsMenuDisplayed(true);
											}
										} else if (isTopDestinationsMenuDisplayed) {
											setIsTopDestinationsMenuDisplayed(false);
										}
										setDestinationInput(inputValue);
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
									loading={displayLoader}
								/>

								<SmartDPDateCalendarContainer
									id="travelDates"
									name="travelDates"
									data-testid="departure-date-input"
									validate={validateDepartureDate}
									className="sdp-search-form__field sdp-search-form__field-dates"
									popperOffset={calendarPopoverOffset}
									updateFloatingPositionReference={
										updateFloatingPositionReference
									}
									{...(isListingOrQuote ? { popoverWidth: 992 } : {})}
									calendarDisabledView={
										<CalendarDisabledView
											mainMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.label" />
												) : (
													<FormattedMessage id="sdp.calendar.no.destination.no.departure.city.label" />
												)
											}
											secondaryMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.sublabel" />
												) : null
											}
										/>
									}
									label={
										<FormattedMessage id="sdp.search.departure.date.input.label" />
									}
									uri={sdpRotationUri}
									handleDayChange={({
										durationsOfSelectedDepartureDate = [],
										selectedEndDate,
										selectedDepartureDate,
									}) => {
										// durationsOfSelectedDepartureDate [{duration, ed}]

										if (isEmpty(durationsOfSelectedDepartureDate)) {
											setFieldValue("duration", {
												code: "",
											});
										} else if (selectedEndDate) {
											const formattedSelectedEndDate = format(
												new Date(selectedEndDate),
												SDP_ROTATION_DATE_FORMAT
											);

											const dataOfSelectedDepartureDate = durationsOfSelectedDepartureDate.find(
												data => data.ed === formattedSelectedEndDate
											);
											if (dataOfSelectedDepartureDate) {
												setFieldValue("duration", {
													code: dataOfSelectedDepartureDate.duration,
												});
											} else if (websiteType === WEBSITE_TYPE.STOPOVER) {
												const daysDifference = differenceInDays(
													new Date(selectedEndDate),
													new Date(selectedDepartureDate)
												);
												setFieldValue("duration", {
													code: daysDifference,
												});
											} else {
												setFieldValue("duration", {
													code: "",
												});
											}
										}
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
									loading={displayLoader}
								/>

								{hasCabins && (
									<SmartDPSearchInput
										id="cabin"
										name="cabin"
										openMenuOnFocus
										className="sdp-search-form__field sdp-search-form__field-cabin"
										data-testid="cabin-input"
										isSearchable={false}
										label={
											<FormattedMessage id="sdp.search.cabin.input.label" />
										}
										getOptionLabel={({ label = "", suffix = "" }) =>
											`${label}${suffix ? `, ${suffix}` : ""}`
										}
										components={{
											SingleValue: InputValueFormat,
											ClearIndicator: ClearIndicatorSdpSearchInput,
										}}
										getOptionValue={({ code }) => code}
										options={cabins}
										value={cabins.find(({ code }) => {
											return code === get(values, "cabin.code");
										})}
										popperWidth={isStopover ? 183 : 196}
										popperOffset={[0, 0]}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
										loading={displayLoader}
									/>
								)}

								{/* force a line break */}
								{isStopover && !isListingOrQuote && (
									<div style={{ width: "100%", height: 0 }} />
								)}

								{isStopover && (
									<SmartDPSearchInput
										id="stopoverCity"
										name="stopoverCity"
										openMenuOnFocus
										className="sdp-search-form__field sdp-search-form__field-stopovercity"
										data-testid="stopover-city-input"
										isSearchable={false}
										label={
											<FormattedMessage id="sdp.search.stopover.city.input.label" />
										}
										drawerTitleLabel={
											<FormattedMessage id="sdp.search.stopover.city.input.label.mobile" />
										}
										getOptionLabel={({ label = "" }) => `${label}`}
										components={{
											SingleValue: InputValueFormat,
											ClearIndicator: ClearIndicatorSdpSearchInput,
										}}
										getOptionValue={({ code }) => code}
										options={stopoverCities}
										value={stopoverCities.find(({ code }) => {
											return code === get(values, "stopoverCity.code");
										})}
										popperWidth={isListingOrQuote ? 180 : 248}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
										loading={displayLoader}
									/>
								)}

								{isStopover && (
									<SmartDPSearchInput
										id="stopoverDirection"
										name="stopoverDirection"
										openMenuOnFocus
										className="sdp-search-form__field sdp-search-form__field-stopoverdirection"
										data-testid="stopover-direction-input"
										isSearchable={false}
										label={
											<FormattedMessage id="sdp.search.stopover.direction.input.label" />
										}
										drawerTitleLabel={
											<FormattedMessage id="sdp.search.stopover.direction.input.label.mobile" />
										}
										getOptionLabel={({ label = "" }) => `${label}`}
										components={{
											SingleValue: InputValueFormat,
											ClearIndicator: ClearIndicatorSdpSearchInput,
										}}
										getOptionValue={({ code }) => code}
										options={stopoverDirections}
										value={stopoverDirections.find(({ code }) => {
											return code === get(values, "stopoverDirection.code");
										})}
										popperWidth={isListingOrQuote ? 180 : 248}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
										loading={displayLoader}
									/>
								)}

								{isMobile ? (
									<TravellersRoomDrawerButton
										className={classNames(
											"sdp-search-form__field sdp-search-form__field-rooms",
											{
												"sdp-search-form__field--fullsize": !hasCabins,
											}
										)}
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										validateOnMount={validateOnMount}
										warningMessage={isUsaDestination}
									/>
								) : (
									<TravellersRoomButton
										className={classNames(
											"sdp-search-form__field sdp-search-form__field-rooms",
											{
												"sdp-search-form__field--fullsize": !hasCabins,
											}
										)}
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										stickyMode={isSticky}
										validateOnMount={validateOnMount}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
										loading={displayLoader}
										popperWidth={travelRoomsPopoverWidth}
										popperOffset={travelRoomsPopoverOffset}
										warningMessage={isUsaDestination}
									/>
								)}

								<div className="sdp-search-form__footer">
									{isListingOrQuote && !isMobile ? (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											disabled={displayLoader || isSubmitting}
										>
											<IconMagnifyingGlass />
										</Button>
									) : (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											onClick={onHandleClickSearchButton}
											disabled={displayLoader || isSubmitting}
										>
											<FormattedMessage id="sdp.search.cta.label" />
										</Button>
									)}
								</div>
							</Form>
							{/* Do not display error message on validationOnMount (redirection from emirates) */}
							{!isValid && submitCount >= 1 && (
								<div className="sdp-search-form__error">
									<FormErrorMessages
										errors={errors}
										isSubmitting={isSubmitting}
										submitCount={submitCount}
										disableErrorsDetails={true}
										message={
											<FormattedMessage id="sdp.search.form.error.message" />
										}
										scrollInToView={errorScrollIntoView}
									/>
								</div>
							)}
						</>
					);
				}}
			</Formik>
		</div>
	);
};

SmartDPSearchForm.propTypes = {
	onSuccess: PropTypes.func,
	departureCities: PropTypes.array,
	isStopover: PropTypes.bool,
	stopoverCities: PropTypes.array,
	stopoverDirections: PropTypes.array,
	destinations: PropTypes.array,
	cabins: PropTypes.array,
	initialValues: PropTypes.object,
	hideSidePanel: PropTypes.func,
	topDestinationsList: PropTypes.array,
	showMiniSDPForm: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	componentToOverlayRef: PropTypes.object,
	handleFormError: PropTypes.func,
	displayLoader: PropTypes.bool,
	brand: brandPropTypes,
	errorScrollIntoView: PropTypes.bool,
	websiteType: websiteTypeShape,
	resetAllSDPProductsFilter: PropTypes.func,
};

export default memo(SmartDPSearchForm);
