import { connect } from "react-redux";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import addHours from "date-fns/addHours";
import { useEffect } from "react";
import SmartDPSearchForm from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import PropTypes from "prop-types";
import { getTopDestinations } from "app/reducers/shopSelector";
import { QUOTATION_CODE_STATUS, RESOLUTION, STOPOVER_DIRECTIONS } from "app/constants";
import { resetAllSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { getAvailableStopoverCities } from "app/pages/SmartDP/smartDPSelectors";
import { injectIntl } from "react-intl";
import { getWebsiteType } from "app/reducers/partnerSelector";

const SmartDPSearchFormContainer = props => {
	const { fetchSDPFilters } = props;

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	return (
		<div className="sdp-search-form__container">
			<SmartDPSearchForm {...props} />
		</div>
	);
};

SmartDPSearchFormContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
	isMobile: PropTypes.bool,
	brand: PropTypes.string,
	disableFormPersistance: PropTypes.bool, // used to avoid fields flickering when coming from merch page with sdp form to home sdp
};

const mapStateToProps = (state, props) => {
	const booking = state.booking;

	const initialValues = {
		travelDates: {},
	};

	if (get(state, "smartDP.cabins[0]")) {
		initialValues.cabin = get(state, "smartDP.cabins[0]");
	}

	if (booking.cabin?.code) {
		initialValues.cabin = booking.cabin;
	}

	if (
		!props.disableFormPersistance &&
		booking.destinationResort?.code &&
		state.smartDP.destinations
	) {
		const destination = state.smartDP.destinations.find(
			destination => destination.code.toString() === booking.destinationResort.code
		);
		if (destination) {
			initialValues.destinationResort = {
				...booking.destinationResort,
				departureDateStart: destination.departureDateStart,
				departureDateEnd: destination.departureDateEnd,
			};
		}
	}
	//
	if (!props.disableFormPersistance && booking.departureCity?.code) {
		initialValues.departureCity = booking.departureCity;
	}

	if (!props.disableFormPersistance && booking.departureDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let departureDate = new Date(booking.departureDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		departureDate = addHours(departureDate, departureDate.getTimezoneOffset() / 60);
		// departureDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.departureDate = departureDate;
	}

	if (booking.endDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let endDate = new Date(booking.endDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		endDate = addHours(endDate, endDate.getTimezoneOffset() / 60);
		// endDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.endDate = endDate;
	}

	if (booking.occupancies?.length > 0) {
		initialValues.occupancies = booking.occupancies;
	}

	if (booking.duration?.value) {
		initialValues.duration = {
			code: booking.duration.value,
		};
	}

	const stopoverCities = getAvailableStopoverCities(state);

	if (booking.stopoverCityId) {
		initialValues.stopoverCity = {
			code: booking.stopoverCityId,
		};
	} else {
		initialValues.stopoverCity = stopoverCities && stopoverCities[0];
	}

	const stopoverDirections = [
		{
			label: props.intl.formatMessage({ id: "sdp.search.stopover.outbound.label" }),
			code: STOPOVER_DIRECTIONS.OUTBOUND,
		},
		{
			label: props.intl.formatMessage({ id: "sdp.search.stopover.inbound.label" }),
			code: STOPOVER_DIRECTIONS.INBOUND,
		},
	];

	if (booking.stopoverDirection) {
		initialValues.stopoverDirection = {
			code: booking.stopoverDirection,
			label: stopoverDirections.find(
				direction => direction.code === booking.stopoverDirection
			).label,
		};
	} else {
		initialValues.stopoverDirection = {
			label: props.intl.formatMessage({ id: "sdp.search.stopover.outbound.label" }),
			code: STOPOVER_DIRECTIONS.OUTBOUND,
		};
	}

	return {
		initialValues,
		destinations: state.smartDP.destinations,
		departureCities: state.smartDP.departureCities,
		stopoverCities: stopoverCities,
		stopoverDirections,
		cabins: state.smartDP.cabins,
		validateOnMount: state.smartDP.status === QUOTATION_CODE_STATUS.BAD_REQUEST,
		topDestinationsList: getTopDestinations(state) || [],
		isMobile: [RESOLUTION.SMALL, RESOLUTION.MEDIUM].includes(state?.resolution),
		websiteType: getWebsiteType(state),
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
			resetAllSDPProductsFilter,
		},
		dispatch
	);
};

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SmartDPSearchFormContainer)
);
