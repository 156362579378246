import { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { useField } from "formik";
import DepartureDateSidePanelContent from "app/pages/SmartDP/Search/DepartureDateSidePanelContent/DepartureDateSidePanelContent";
import { FormattedDate } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./DateCalendarInput.scss";
import classNames from "classnames";
import useHidePartnerCustomHeader from "app/utils/hooks/useHidePartnerCustomHeader";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const DateCalendarDrawerInput = props => {
	const [field, meta, helpers] = useField(props);
	const {
		id,
		label,
		className,
		icon,
		departureDateMin,
		departureDateMax,
		checkDateAvailability = () => {},
		isCalendarDisabled,
		calendarDisabledView,
		onClose = () => {},
	} = props;

	const [open, setOpen] = useState(false);
	useHidePartnerCustomHeader({ hide: open });

	const { setError } = helpers;

	const handleClose = useCallback(() => {
		if (!field?.value?.endDate) {
			helpers.setValue({});
		}
		setOpen(false);
		onClose();
	}, [field?.value?.endDate]);

	const handleOpen = useCallback(() => {
		setOpen(true);
		setError();
	}, []);

	const handleDateSelect = useCallback(date => {
		helpers.setValue({
			departureDate: date[0],
			endDate: date[1],
		});
	}, []);

	const handleDateConfirmation = date => {
		if (date.length !== 2) {
			helpers.setValue({});
		}
		helpers.setTouched(true);
		setOpen(false);
	};

	const isTouched = (meta.touched && !meta.error) || open || field.value?.departureDate;

	const inputClassName = classNames("date-calendar-input", className, {
		"date-calendar-input--opened": open,
		"date-calendar-input--touched": isTouched,
		"date-calendar-input--error": meta.touched && meta.error,
		"date-calendar-input--with-icon": icon,
	});

	return (
		<>
			<div
				className={inputClassName}
				onClick={handleOpen}
				data-testid={props["data-testid"]}
				tabIndex="0"
				onFocus={handleOpen}
			>
				{icon && <div className="date-calendar-input__icon">{icon}</div>}
				<label htmlFor={id} className="date-calendar-input__label">
					{isTouched ? (
						<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
							{label}
						</Typography>
					) : (
						<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>{label}</Typography>
					)}
				</label>
				<div className="date-calendar-input__input">
					{field.value?.departureDate && (
						<FormattedDate
							value={field.value?.departureDate}
							day="2-digit"
							month="2-digit"
							year={"numeric"}
						/>
					)}
					{field.value?.endDate && (
						<FormattedDate
							value={field.value?.endDate}
							day="2-digit"
							month="2-digit"
							year={"numeric"}
						>
							{dateToDisplay => ` - ${dateToDisplay}`}
						</FormattedDate>
					)}
				</div>
			</div>
			<SidePanel isOpen={open} onClose={handleClose} direction={"rtl"}>
				<DepartureDateSidePanelContent
					onClose={handleClose}
					initialDate={[field.value?.departureDate, field.value?.endDate]}
					onDateSelect={handleDateSelect}
					onDateConfirm={handleDateConfirmation}
					departureDateMin={departureDateMin}
					departureDateMax={departureDateMax}
					checkDateAvailability={checkDateAvailability}
					isCalendarDisabled={isCalendarDisabled}
					calendarDisabledView={calendarDisabledView}
				/>
			</SidePanel>
		</>
	);
};

DateCalendarDrawerInput.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	className: PropTypes.string,
	departureDateMin: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	departureDateMax: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	icon: PropTypes.element,
	checkDateAvailability: PropTypes.func,
	["data-testid"]: PropTypes.string,
	isCalendarDisabled: PropTypes.bool,
	calendarDisabledView: PropTypes.element,
	onClose: PropTypes.func,
};

export default memo(DateCalendarDrawerInput);
